import { t } from 'bv-i18n';
import {
  apiFetchSelfExclusionPeriods,
  apiFetchSelfExclusionCauses,
  apiSelfExcludeUser,
} from './api';

export const FETCH_SELF_EXCLUSION_PERIODS_COMPLETE = 'SELF_EXCLUSION.FETCH_SELF_EXCLUSION_PERIODS.COMPLETE';
export const FETCH_SELF_EXCLUSION_CAUSES_COMPLETE = 'SELF_EXCLUSION.FETCH_SELF_EXCLUSION_CAUSES.COMPLETE';
export const SELF_EXCLUDE_USER_INIT = 'SELF_EXCLUSION.SELF_EXCLUDE_USER.INIT';
export const SELF_EXCLUDE_USER_SUCCESS = 'SELF_EXCLUSION.SELF_EXCLUDE_USER.SUCCESS';
export const SELF_EXCLUDE_USER_FAILURE = 'SELF_EXCLUSION.SELF_EXCLUDE_USER.FAILURE';
export const CLOSE_SUBMIT_RESULT_MODAL = 'SELF_EXCLUSION.CLOSE_SUBMIT_RESULT_MODAL';

export const fetchSelfExclusionPeriodsComplete = (periods) => ({
  type: FETCH_SELF_EXCLUSION_PERIODS_COMPLETE,
  periods,
});

export const fetchSelfExclusionCausesComplete = (causes) => ({
  type: FETCH_SELF_EXCLUSION_CAUSES_COMPLETE,
  causes,
});

export const selfExcludeUserInit = () => ({
  type: SELF_EXCLUDE_USER_INIT,
});

export const selfExcludeUserSuccess = () => ({
  type: SELF_EXCLUDE_USER_SUCCESS,
});

export const selfExcludeUserFailure = (error) => ({
  type: SELF_EXCLUDE_USER_FAILURE,
  error,
});

export const closeSubmitResultModal = () => ({
  type: CLOSE_SUBMIT_RESULT_MODAL,
});

export const fetchSelfExclusionPeriods = () => (dispatch) => (
  apiFetchSelfExclusionPeriods().then((data) => {
    dispatch(fetchSelfExclusionPeriodsComplete(data));
  })
);

export const fetchSelfExclusionCauses = () => (dispatch) => (
  apiFetchSelfExclusionCauses().then((data) => {
    dispatch(fetchSelfExclusionCausesComplete(data));
  })
);

export const selfExcludeUser = (params, onSuccess) => (dispatch) => {
  dispatch(selfExcludeUserInit());

  return apiSelfExcludeUser(params).then((selfExcludeResponse) => {
    if (selfExcludeResponse.success) {
      dispatch(selfExcludeUserSuccess());
      onSuccess();
    } else if (selfExcludeResponse.wrongPassword) {
      dispatch(selfExcludeUserFailure(t('javascript.self_exclusion.invalid_password')));
    } else {
      dispatch(selfExcludeUserFailure(t('javascript.self_exclusion.general_error')));
    }
  });
};

export const initialState = {
  selfExclusionPeriods: [],
  selfExclusionCauses: [],
  showSelfExclusionPeriods: false,
  selfExclusionPeriodsLoaded: false,
  selfExclusionCausesLoaded: false,
  userIsSelfExcluding: false,
  userSelfExclusionFailed: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SELF_EXCLUSION_PERIODS_COMPLETE: {
      return {
        ...state,
        selfExclusionPeriodsLoaded: true,
        selfExclusionPeriods: action.periods,
        showSelfExclusionPeriods: action.periods.length > 1,
      };
    }
    case FETCH_SELF_EXCLUSION_CAUSES_COMPLETE: {
      return {
        ...state,
        selfExclusionCausesLoaded: true,
        selfExclusionCauses: action.causes,
      };
    }
    case SELF_EXCLUDE_USER_INIT: {
      return {
        ...state,
        userIsSelfExcluding: true,
      };
    }
    case SELF_EXCLUDE_USER_SUCCESS: {
      return {
        ...state,
        userIsSelfExcluding: false,
      };
    }
    case SELF_EXCLUDE_USER_FAILURE: {
      return {
        ...state,
        userIsSelfExcluding: false,
        userSelfExclusionFailed: true,
        error: action.error,
      };
    }
    case CLOSE_SUBMIT_RESULT_MODAL: {
      return {
        ...state,
        userSelfExclusionFailed: false,
        error: null,
      };
    }
    default:
      return state;
  }
};
